const scrollToSection = (givenElement = 'footer') => {
  if (givenElement === null) {
    return;
  }

  const element = document.getElementById(givenElement);
  element?.scrollIntoView({ behavior: 'smooth' });
};

export default scrollToSection;
