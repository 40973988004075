import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import { getProductByCategory } from '../../../../recoil/products';
import { createContext, useContext, useEffect, useRef } from 'react';

const CategorySectionContext = createContext();

export default function CategorySection({
  categoryName,
  children,
  idx,
  setCategoriesIndex,
  categoriesIndex,
}) {
  const { isAuthenticated } = useAuth0();
  const category = useRecoilValue(getProductByCategory(categoryName));

  const { t } = useTranslation();

  const sectionRef = useRef(null);

  useEffect(() => {
    setCategoriesIndex(current =>
      current.map((item, index) => {
        if (index === idx && !category) {
          return undefined;
        } else if (index === idx && category) {
          return categoryName;
        }
        return item;
      })
    );
  }, [category, idx, setCategoriesIndex, categoryName]);

  if (categoriesIndex.every(item => item === undefined) && idx === 0) {
    return (
      <section
        className={'border-top ' + (isAuthenticated ? 'scroll-margin-top' : '')}
        style={{ position: 'relative' }}
        ref={sectionRef}
      >
        <h5
          style={{
            padding: '60px 0',
            margin: 0,
            fontWeight: 800,
            marginLeft: 20,
          }}
        >
          {t('articles_not_found')}
        </h5>
      </section>
    );
  }

  if (categoriesIndex[idx] === undefined) {
    return;
  }

  return (
    <CategorySectionContext.Provider value={{ categoryName, category }}>
      <section
        className={'mb-5 ' + (isAuthenticated ? 'scroll-margin-top' : '')}
        id={categoryName}
      >
        {children}
      </section>
    </CategorySectionContext.Provider>
  );
}

function Heading() {
  const { isAuthenticated } = useAuth0();
  const { categoryName, category } = useContext(CategorySectionContext);

  if (!category) return;

  return (
    <div
      className={`sticky-header ${
        isAuthenticated ? 'sticky-header-authenticated' : ''
      }`}
    >
      <h5>
        {/* {categoryName === 'with_label_section' ? t(categoryName) : categoryName} */}
        {categoryName}
      </h5>
    </div>
  );
}

function Products({ render }) {
  const { category } = useContext(CategorySectionContext);
  if (!category) return;

  return (
    <div className="row g-2 g-sm-4 g-lg-5 mb-1 justify-content-center justify-content-sm-start">
      {category?.products?.map(render)}
    </div>
  );
}

CategorySection.Heading = Heading;
CategorySection.Products = Products;
