import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import handleProductLanguage from '../../../../../../utils/handleProductLanguage';
import handleProductColorChange from '../../../../../../utils/handleColorChange';
import ProductImage from './components/product-image';
import Spinner from '../../../../../spinner';
import ColorButton from './components/color-button';
import ControlCartButton from './components/control-cart-button';
import Attribute from './components/attribute';
import { useRecoilState } from 'recoil';
import labelFilter from '../../../../../../recoil/label-filter';
import { ALERT_DANGER } from '../../../../../../constants/alert';
import { useAuth0 } from '@auth0/auth0-react';
import useMessageAlert from '../../../../../../hooks/useMessageAlert';

export default memo(function ProductGroup({ productItems, category }) {
  const { isAuthenticated } = useAuth0();
  const { setAlert } = useMessageAlert();

  const language = localStorage.getItem('i18nextLng');

  const [currentColorProduct, setCurrentColorProduct] = useState(
    productItems[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [lblFilter] = useRecoilState(labelFilter);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const colorsIds = productItems.map(item => {
    return {
      color:
        item?.design?.color === 'aluminium kugelpoliert'
          ? 'aluminium-weiss'
          : item?.design?.color,
      id: item?.id,
    };
  });

  const handleColorChange = handleProductColorChange(
    productItems,
    setIsLoading,
    setCurrentColorProduct
  );

  if (category === 'EDIZIOdue prestige') {
    const text =
      language === 'fr'
        ? 'Avez-vous besoin d EDIZIOdue Prestige comme échantillon?'
        : language === 'it'
        ? 'Hai bisogno di EDIZIOdue Prestige come campione?'
        : 'Benötigen sie EDIZIOdue Prestige als Muster?';

    return (
      <div
        className={`${
          isLoading ? 'position-relative' : ''
        } col-12 col-sm-6 col-md-4 col-lg-3 pt-5 pt-sm-3`}
      >
        {isLoading && (
          <div className="card-overlay pe-2 pe-sm-4 pe-lg-5 mb-1">
            <Spinner />
          </div>
        )}

        <div className="d-flex flex-column d-sm-block">
          <div className="d-flex align-items-center gap-3 product-image-wrapper">
            <img
              src="./prestige_2012.jpg"
              alt="product header"
              className="card-img-top product-image-b"
            />
          </div>
          <div className="d-flex flex-column-reverse d-sm-block ">
            <div className="d-flex flex-wrap gap-2 pt-3"></div>

            <h5 className="card-title card-image-text">{text}</h5>
          </div>

          <div className="pt-3">
            <label
              role="button"
              className={`card-link btn btn-primary btn-sm d-inline-flex justify-content-center align-items-start gap-1 `}
              onClick={() => {
                if (!isAuthenticated)
                  return setAlert(
                    t('not_authenticated_message'),
                    ALERT_DANGER,
                    'top-0'
                  );

                if (isAuthenticated) {
                  window.location.href = `mailto:${process.env.REACT_APP_ADMIN_EMAIL}`;
                }
              }}
            >
              <input
                name="select-button"
                className="form-check-input position-absolute btn opacity-0"
                type="checkbox"
                disabled={!isAuthenticated}
              />
              <small>{t('please_contact_us')}</small>
            </label>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${
        isLoading ? 'position-relative' : ''
      } col-12 custom-sm-width col-md-4 col-lg-3 pt-5 pt-sm-3`}
    >
      {isLoading && (
        <div className="card-overlay pe-2 pe-sm-4 pe-lg-5 mb-1">
          <Spinner />
        </div>
      )}
      {currentColorProduct?.image_label_url &&
        lblFilter &&
        category === 'with_label_section' && (
          <h5 className="card-title-top">{currentColorProduct.category}</h5>
        )}

      <div className="d-flex flex-column d-sm-block">
        <div className="d-flex align-items-center gap-3">
          <ProductImage
            url={`/assets/images/${currentColorProduct?.image_name}`}
          />
          {/* <div className="d-sm-none">
            <Attribute
              name={t('nFeller')}
              value={currentColorProduct?.feller_number}
            />
            <Attribute name={t('enr')} value={currentColorProduct?.e_nr} />
            <Attribute name="EAN" value={currentColorProduct?.ean} />
          </div> */}
        </div>
        <div className="d-flex flex-column-reverse d-sm-block ">
          <div className="d-flex flex-wrap gap-2 pt-3">
            {/*{currentColorProduct?.new && (*/}
            {/*  <InfoButton text={t('new_product')} color="danger" />*/}
            {/*)}*/}
            {/*{currentColorProduct?.image_label_url &&*/}
            {/*  lblFilter &&*/}
            {/*  category === 'with_label_section' && (*/}
            {/*    <InfoButton*/}
            {/*      text={`${currentColorProduct.category}`}*/}
            {/*      color="danger"*/}
            {/*    />*/}
            {/*  )}*/}
          </div>

          <h5 className="card-title pt-1">
            {handleProductLanguage(currentColorProduct?.name)}
          </h5>
        </div>
        {!isMobile && (
          <div className="d-flex flex-wrap pt-2">
            {colorsIds?.map(item => (
              <ColorButton
                isMobile={isMobile}
                id={item.id}
                color={item.color}
                handleColorChange={handleColorChange}
                isCurrent={item.id === currentColorProduct?.id}
                key={item.id + item.color}
              />
            ))}
          </div>
        )}

        {isMobile && (
          <div
            className="pt-2"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(50%, 1fr))',
            }}
          >
            {colorsIds?.map(item => (
              <ColorButton
                isMobile={isMobile}
                id={item.id}
                color={item.color}
                handleColorChange={handleColorChange}
                isCurrent={item.id === currentColorProduct?.id}
                key={item.id + item.color}
              />
            ))}
          </div>
        )}
        <div className="pt-3">
          <ControlCartButton
            id={currentColorProduct?.id}
            isLoading={isLoading}
            addItem={t('add_item_button')}
            removeItem={t('remove_item_button')}
          />
        </div>
        <div className=" d-sm-block pt-3">
          <Attribute
            name={t('nFeller')}
            value={currentColorProduct?.feller_number}
          />
          <Attribute name={t('enr')} value={currentColorProduct?.e_nr} />
          <Attribute name="EAN" value={currentColorProduct?.ean} />
        </div>
      </div>
    </div>
  );
});
