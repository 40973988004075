import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';

import useMessageAlert from '../../../../hooks/useMessageAlert';
import useHandleForm from '../../../../hooks/useHandleForm';
import { createOrder } from '../../../../actions/orders';
import { getLanguage } from '../../../../recoil/language';
import selectedProducts from '../../../../recoil/selected-products';
import DifferentAddress from './components/differentAddress';
import OtherReason from './components/otherReason';
import ReasonRadio from './components/reasonRadio';
import Checkbox from './components/checkbox';
import SubmitButton from './components/submitButton';
import ConfirmOrderModal from './components/confirmOrderModal';
import { ALERT_DANGER, ALERT_SUCCESS } from '../../../../constants/alert';
import {
  DATA_PROTECTION_URL,
  GENERAL_SALE_CONDITIONS_URL,
} from '../../../../constants/footer';
import customCompanyState from '../../../../recoil/custom-company/atom';
import scrollToSection from '../../../../utils/scrollToSection';

export default function OrderForm() {
  const [cartProducts, setCartProducts] = useRecoilState(selectedProducts);
  const { getAccessTokenSilently } = useAuth0();
  const { t, i18n } = useTranslation();
  const language = useRecoilValue(getLanguage);
  const { setAlert } = useMessageAlert();
  const customCompany = useRecoilValue(customCompanyState);

  const {
    methods,
    shouldUserInputReasonText,
    isOtherChecked,
    isDifferentAddress,
    isCheckboxDisabled,
    userData,
  } = useHandleForm(cartProducts, language, onSubmit);

  const { mutate, isLoading } = useMutation({
    mutationFn: async formData => {
      const authToken = await getAccessTokenSilently();

      return createOrder(
        {
          ...formData,
          ...(customCompany && customCompany.length >= 2
            ? { customCompany }
            : {}),
        },
        authToken
      );
    },
    onSuccess: async () => {
      setAlert(t('order_success_message'), ALERT_SUCCESS, 'top-115');
      methods.reset();
      setCartProducts([]);
    },
    onError: async ({ response }) => {
      if (response.data.error.code === 'order_no_company') {
        scrollToSection('companyName');
      }
      setAlert(t(response.data.error.code), ALERT_DANGER, 'top-115');
    },
  });

  async function onSubmit(data, e) {
    const fieldsToDelete = [
      'differentAddressCheck',
      'avbCheck',
      'dataAndPrivacyCheck',
    ];

    for (const field of fieldsToDelete) {
      if (field in data) {
        delete data[field];
      }
    }

    e.preventDefault();

    mutate({
      language: language,
      ...data,
    });
  }

  return (
    <FormProvider {...methods}>
      <form
        className="container-md mx-auto"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <h5 className="mb-4 mt-5 fw-bolder">{t('address_heading')}</h5>
        <div className="row g-0">
          {!isDifferentAddress && (
            <div className="form-check-label mb-3">
              {t('deliver_to')}:{' '}
              <em>
                {userData.street}, {userData.city}, {userData.zip}
              </em>
            </div>
          )}

          <Checkbox
            disabled={isCheckboxDisabled}
            checkId="differentAddressCheck"
            label={t('different_delivery_address')}
            label2={t('different_delivery_address')}
          />
        </div>

        {isDifferentAddress && <DifferentAddress />}

        <div className="row g-0 pt-5">
          <h5 className="mb-4 fw-bolder">{t('order_reason_heading')}</h5>

          <ReasonRadio
            reasonDE={t('first_reason_order_label', { lng: 'de' })}
            reasonFR={t('first_reason_order_label', { lng: 'fr' })}
            reasonIT={t('first_reason_order_label', { lng: 'it' })}
            label={t('first_reason_order_label')}
          />

          <ReasonRadio
            reasonDE={t('second_reason_order_label', { lng: 'de' })}
            reasonFR={t('second_reason_order_label', { lng: 'fr' })}
            reasonIT={t('second_reason_order_label', { lng: 'it' })}
            label={t('second_reason_order_label')}
          />

          <ReasonRadio
            reasonDE={t('third_reason_order_label', { lng: 'de' })}
            reasonFR={t('third_reason_order_label', { lng: 'fr' })}
            reasonIT={t('third_reason_order_label', { lng: 'it' })}
            label={t('third_reason_order_label')}
          />

          <ReasonRadio
            reasonDE={t('other_reason_order_label', { lng: 'de' })}
            reasonFR={t('other_reason_order_label', { lng: 'fr' })}
            reasonIT={t('other_reason_order_label', { lng: 'de' })}
            label={t('other_reason_order_label')}
          />

          {isOtherChecked && (
            <OtherReason otherReasonText={t('other_reason_text')} />
          )}

          {shouldUserInputReasonText && (
            <OtherReason otherReasonText={t('additional_reason_text')} />
          )}
        </div>

        <div className="col-12 pt-4">
          <h5 className="mb-4 fw-bolder">{t('data_protection')}</h5>
          <p>{t('agree_statement')}</p>
          <div className="d-flex flex-column gap-3">
            <Checkbox
              checkId="dataAndPrivacyCheck"
              label={
                <Trans i18nKey="agree_label_data_protection">
                  I agree to
                  <a
                    className="text-decoration-underline text-danger"
                    href={DATA_PROTECTION_URL[i18n.language]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    the terms and conditions
                  </a>
                </Trans>
              }
              required={t('error_accept_data')}
            />
            <Checkbox
              checkId="avbCheck"
              label={
                <Trans i18nKey="agree_label_avb">
                  I agree to
                  <a
                    className="text-decoration-underline text-danger"
                    href={GENERAL_SALE_CONDITIONS_URL[i18n.language]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    the terms and conditions
                  </a>
                </Trans>
              }
              required={t('error_accept_avb')}
            />
          </div>

          <SubmitButton
            submitText={t('submit_button')}
            sendingText={t('sending_button')}
            isDisabled={
              cartProducts.length === 0 ||
              isLoading ||
              methods.formState.isSubmitting ||
              !methods.formState.isValid
            }
          />
        </div>
        <ConfirmOrderModal
          modalMessage={t('confirm_order_modal_message')}
          isSubmitting={isLoading || methods.formState.isSubmitting}
        />
      </form>
    </FormProvider>
  );
}
