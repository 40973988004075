import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { getProductCategories } from '../../recoil/products';
import CategorySection from './components/category-section';
import CategorySelect from './components/category-select';
import ProductGroup from './components/category-section/components/product-group';
import GenericProduct from './components/category-section/components/generic-product';
import labelFilter from '../../recoil/label-filter';
import searchQuery from '../../recoil/search';
import { useAuth0 } from '@auth0/auth0-react';
import FooterAuthAlert from '../footer/components/footer-auth-alert';
function Content() {
  const { t } = useTranslation();
  const { isAuthenticated, isLoading } = useAuth0();

  const categories = useRecoilValue(getProductCategories);
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [categoriesIndex, setCategoriesIndex] = useState(categories);
  const [lblFilter, setLblFilter] = useRecoilState(labelFilter);
  const [searchQueryValue] = useRecoilState(searchQuery);
  const headingRef = useRef(null);

  const mappedFilteredCategories = filteredCategories.filter(category =>
    lblFilter && !searchQueryValue ? true : category !== 'with_label_section'
  );

  useEffect(() => {
    if (headingRef.current) {
      if (searchQueryValue.length < 2) return;
      const headingPosition = headingRef.current.getBoundingClientRect();
      let scrollPosition = headingPosition.top + window.scrollY - 150;

      if (window.innerWidth < 800) {
        scrollPosition = headingPosition.top + window.scrollY;
      }
      setTimeout(
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' }),
        100
      );
    }
  }, [categoriesIndex, searchQueryValue]);

  return (
    <div className="container-md my-5">
      {!isAuthenticated && !isLoading && (
        <FooterAuthAlert alertText={t('sign_in_alert')} />
      )}
      <p className="mt-5 heading-2">{t('main_paragraph')}</p>
      <p className="mt-4 heading-5">{t('first_paragraph')}</p>
      <p className="mb-1 heading-5 ">{t('second_paragraph')}</p>
      <br />
      <p className="mb-1 heading-5">{t('third_paragraph')}</p>
      <br />
      <p className="heading-5 fw-bolder">{t('category_heading')}</p>
      <div className="row px-2 mt-3 mb-2" ref={headingRef}>
        {categories.map((category, idx) => (
          <CategorySelect
            idx={idx}
            name={category}
            src={`/${category.replaceAll(' ', '_')}.png`}
            // src={
            //   idx === 4
            //     ? '/EDIZIOdue_colore_mit_Beschriftungmuster.png'
            //     : `/${category.replaceAll(' ', '_')}.png`
            // }
            key={category}
            onClick={() => {
              if (category === 'with_label_section') {
                setLblFilter(true);
              } else {
                setLblFilter(false);
              }
              const updatedCategories = [
                category,
                ...categories.filter(cat => cat !== category),
              ];
              setFilteredCategories(updatedCategories);
            }}
          />
        ))}
      </div>
      {mappedFilteredCategories.map((category, idx) => (
        <CategorySection
          categoryName={category}
          key={category}
          idx={idx}
          setCategoriesIndex={setCategoriesIndex}
          categoriesIndex={categoriesIndex}
          headingRef={headingRef}
        >
          <CategorySection.Heading />
          <CategorySection.Products
            render={product => (
              <ProductGroup
                productItems={product?.items}
                category={category}
                key={product?.items[0]?.id}
              />
            )}
          />
        </CategorySection>
      ))}
      <section className="mb-3 pt-5 border-top contact-us">
        <h5 className="my-2y me-4 contact-us">
          {t('product_not_found_title')}
        </h5>
        <GenericProduct />
      </section>
    </div>
  );
}

export default Content;
