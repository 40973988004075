import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import SelectedProducts from './components/selected-products';
import isProffesionalUser from '../../utils/isProfessionalUser';
import OrderForm from './components/order-form';
import AccordionMenu from './components/accordion-menu';
import FooterMenuGrid from './components/menu-grid';
import Logo from '../logo';
import SocialLinks from './components/social-links';
import CommonLinks from './components/common-links';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import customCompanyState from '../../recoil/custom-company/atom';

function Footer() {
  const { user, isAuthenticated } = useAuth0();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [customCompany, setCustomCompany] = useRecoilState(customCompanyState);
  const location = useLocation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      customCompany,
    },
  });

  useEffect(() => {
    setValue('customCompany', customCompany);
  }, [customCompany, setValue]);

  const userMetadata = user ? user['https://id.feller.ch/user_metadata'] : null;
  const userWorkFunction = userMetadata?.function
    ? userMetadata.function.replaceAll('-', '_').toString()
    : '';

  const routesWithoutFooter = ['/unauthorized', '/status', '/'];

  const shouldRenderFooter = routesWithoutFooter.some(
    route => location.pathname === route
  );

  const onSave = data => {
    setCustomCompany(data.customCompany);
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);
  };

  return (
    <>
      {shouldRenderFooter && isAuthenticated && (
        <div className="container-fluid px-0  pb-3 ">
          {isAuthenticated && isProffesionalUser(userMetadata?.user_type) && (
            <>
              <SelectedProducts />
              <div className="container-md">
                <h5 className="heading-2 border-top pt-5">{user?.name}</h5>
                <p className="heading-5">
                  {t('function')}: {t(userWorkFunction)}
                </p>

                {userMetadata?.company_details?.headquarter?.name && (
                  <p className="heading-5" style={{ display: 'flex', gap: 2 }}>
                    {t('companyLbl')}:{' '}
                    {userMetadata?.company_details?.headquarter?.name}
                  </p>
                )}
              </div>
              <div id="companyName" />
              {!userMetadata?.company_details?.headquarter?.name && (
                <div className="container-md">
                  <h5 className="mb-4 mt-5 fw-bolder">{t('companyLbl')}</h5>
                  <form
                    onSubmit={handleSubmit(onSave)}
                    style={{
                      display: 'flex',
                      gap: 10,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: 10,
                        width: '50%',
                        marginBottom: 14,
                      }}
                      className="form-floating"
                    >
                      <Controller
                        name="customCompany"
                        control={control}
                        defaultValue={
                          userMetadata?.company_details?.headquarter?.name || ''
                        }
                        rules={{
                          required: {
                            value: true,
                            message: t('error_empty_field'),
                          },
                          minLength: {
                            value: 2,
                            message: t('error_min_length'),
                          },
                          maxLength: {
                            value: 50,
                            message: t('error_max_length'),
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <input
                              type="text"
                              className={`form-control   ${
                                errors.customCompany ? 'border-danger' : ''
                              }`}
                              {...field}
                              onChange={e => {
                                field.onChange(e);
                                setCustomCompany(e.target.value);
                              }}
                            />
                            <label
                              htmlFor="street"
                              className={`${
                                errors.customCompany ? 'text-danger' : ''
                              }`}
                            >
                              {t('companyLbl')}
                            </label>
                          </>
                        )}
                      />

                      <button
                        type="submit"
                        className="col-3 btn btn-primary btn-sm m-0"
                        style={{ height: 57 }}
                        disabled={Object.keys(errors).length !== 0}
                      >
                        {isSaving ? t('saved') : t('save')}
                      </button>
                    </div>
                    <div className="d-flex items-start col-6">
                      <InformationCircleIcon
                        className="me-2"
                        style={{
                          maxWidth: 24,
                          marginBottom: 35,
                        }}
                      />
                      <p className="mb-1">{t('select_no_company_info')}</p>
                    </div>
                  </form>
                </div>
              )}
              <OrderForm />
            </>
          )}
        </div>
      )}

      <footer className="container-fluid py-5 px-0">
        <div className="container-md text-start">
          {/* accordion menu on mobile, grid menu on non-mobile screens */}
          <AccordionMenu />
          <FooterMenuGrid />

          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-5">
            <Logo className="text-center text-md-start align-self-center" />

            <SocialLinks />
          </div>
          <CommonLinks />
          <p className="text-center paragraph-5 m-0">
            Copyright © {new Date().getFullYear()}
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
